.simple-tab .MuiButtonBase-root {
    text-transform: none;
    padding: 12px;
    margin-right: 4px;
    font-size: 13px;
    color: #212121;
    min-height: inherit;
    
    
}

.simple-tab .Mui-selected {
    background: #fff;
    border-radius: 10px 10px 0px 0px;
}

.simple-tab .MuiTabs-indicator {
    border-radius: 2px 2px 0px 0px;
}

.simple-tab-panel .MuiBox-root{
    padding: 10px 0px !important;
}

 