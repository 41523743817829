 

 
.navbar {
    /* padding: 15px 10px; */
    background: #fff !important;
    border: none;
    border-radius: 10px;
    margin-bottom: 30px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

 

 
 
 
 
 

/* a[data-bs-toggle="collapse"] {
    position: relative;
    
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
} */

 
 

 
/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

 

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
}



a[data-bs-toggle="dropdown"] {
    position: relative;
    
}

.dropdown-toggle {
    white-space: nowrap;
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    right: -15px;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .admin-title{
    font-size: 0.85em;
    font-weight: bold;
    line-height: 0.3em;
    font-family: 'Poppins', sans-serif;
}
.admin-title2{
    font-size: 0.7em;
}

.data-sources{
    margin-left: 13px;
}
.data-nav{
    display:flex;
    flex-direction:row;
    margin-left: 13px;
    line-height: 0.3em;
     
}
.data-sources1{
    color: blue;
}

.search-box .form-control{
    border-radius: 1rem;
    border: none;
    background-color: #fff !important;
    padding: 0.6rem 0.75rem !important;
    
}
.input-group-text{
    border-radius: 1rem;
    padding: 0.6rem 0.75rem !important;
    border: none;
    background-color: #fff !important;
}

.search-box {
    border-radius: 1rem !important;
    border: 1px solid #eee !important;
}

.user-profile-wrapper {
    cursor: pointer;
    border-radius: 1rem;
    padding: 3px 3px 0 3px;

}

.user-profile-wrapper:hover {
    background-color: #e7e7e78a;
}

 
.head.MuiToolbar-root {
    min-height: 47px !important;
}

 