 .card-box {
    margin-left: 30px;
 
}
.items{
}
.btn{
    border-radius: 10px;
    cursor:pointer;
    padding: 0.3rem 1.2rem;
    
}
 
.card-body{
    text-align: center;
}
.card{
    background: #e7e7e78a;
    margin: 18px;
    border: none;

}

.scroll { 
    width: 260px;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: justify;
}


a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}
 

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

 
.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

.sidebar {
    min-height: 100vh;
    color: black;
    transition: all 0.3s;
    background: #fff;
} 
.sidebar.active {
    margin-left: -250px;
}

.sidebar .sidebar-header {
    padding: 20px 30px;
    /* background: #6d7fcc; */
}

.sidebar ul.components {
    padding: 8px 0;
    /* border-bottom: 1px solid #47748b; */
}

.sidebar .sidebar-body {
    padding: 0 10px;
    height: calc(100vh - 5.5rem);
    overflow-y: auto;
}

.sidebar .sidebar-body::-webkit-scrollbar {
    width: 4px;
}
.sidebar .sidebar-body::-webkit-scrollbar-thumb {
    background: #4d95fe40;
    border-radius: 10px
}
.sidebar .sidebar-body::-webkit-scrollbar-track {
    /* background: #f1f1f1; */
    background: inherit;
    border-radius: 10px;
}

.card-text{
    color: #000000;
    width:90%;
    font-size: 13px;
    margin-bottom:1rem;
    /* margin-left: 12px; */
}

.sidebar ul li a {
    padding: 10px;
    padding-left: 23px;
    font-size: .95em;
    display: block;
}

.sidebar ul li a:hover {
    color: #1976d2;
    /* background: #fff; */
}
 
.sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #1976d2;
    background: #ebebeb;
    border-radius: 10px 10px 0 0;
}

a[data-bs-toggle="collapse"] {
    position: relative;
    
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.95em !important;
    padding-left: 30px !important;
    /* background: #ebebeb;    */
}


#srSubmenu, #rfpSubmenu, #tendersSubmenu, #quotationsSubmenu {
    border-radius: 0 0 10px 10px;
    background: #ebebeb;
}
.ssl {
    border-bottom: 1px solid #fff;
    border-radius: 10px; }

.ssl ul li {
    padding-left: 22px;
}
 
ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

/* #content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
} */

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

/* @media (max-width: 768px) {
    .sidebar {
        margin-left: -250px;
    }
    .sidebar.active {
        margin-left: 0;
    }
    .sidebarCollapse span {
        display: none;
    }
} */



a[data-bs-toggle="dropdown"] {
    position: relative;
    
}

.dropdown-toggle {
    white-space: nowrap;
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    /* content: ""; */
    /* right: -15px; */
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  
 
 
.sidebar-title{
    font-size: 1.1em
}

/* .profile{
    position: absolute;
} */
