.allProfile {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.allProfileInfo{
    display: flex;
    align-items: center;
    color: #444;
}
.infoDesc{
    justify-content: flex-end;
}

.allShowImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.allShowTopTitle {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.allShowUsername {
    font-weight: 600;
}

.allShowUserTitle {
    font-weight: bold;
    font-size: '16px';
}
.allShowInfo{
    display: flex;
    align-items: center;
    margin: 15px 0px;
    color: #444;
}
.allProfileTitle{
    margin-left: 10px;
}
.emailP{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.emailDesc{
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 600;
    color: rgb(175, 170, 170);
}

.aboutDesc{
    margin: 0px;
    letter-spacing: 0em;  
    font-weight: 400;  
    line-height: 1.5em;  
    color: rgb(97, 97, 97);  
    /* font-family: Roboto, sans-serif;   */
    font-size: 0.875rem;
     
}

.cardProf {
    cursor: pointer;
   
    margin-bottom: .75rem;
  
}

.cardProf:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.gridItem:hover{
    background-color: yellow;
}

 
