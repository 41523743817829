body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn { border-radius: .75rem !important; }

.fs-10 {font-size: 10px !important;}
.fs-11 {font-size: 11px !important;}
.fs-12 {font-size: 12px !important;}
.fs-13 {font-size: 13px !important;}
.fs-14 {font-size: 14px !important;}
.fs-15 {font-size: 15px !important;}
.fs-16 {font-size: 16px !important;}
.fs-17 {font-size: 17px !important;}
.fs-18 {font-size: 18px !important;}
.fs-19 {font-size: 19px !important;}
.fs-20 {font-size: 20px !important;}

.dropdown-menu {
  border: none !important;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px;
}

.hide-sm { display: block; }
.hide-bg { display: none; }

@media only screen and (max-width: 767px) {
 .hide-sm { display: none; } 
 .hide-bg { display: block; }
}

.br-10 { border-radius: 10px !important; }

.box-wrapper{
  border-radius: 1.5rem;
  /* margin: 7px;  */
  /* position: absolute;
  left: 0; */
  
}
 

/* .box{
  border-radius: 1.5rem;
  width: 100%;
  background-color: #eee;
} */

.btn-primary {
  color: #fff;
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
}

.text-primary { color: #1976d2 !important; }

.fw-500 { font-weight: 500 !important; }
.fw-600 { font-weight: 600 !important; }
.fw-700 { font-weight: 700 !important; }
.fw-800 { font-weight: 800 !important; }

.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows { 
  margin-bottom: 0rem;
}
.MuiTablePagination-root {
  border: none !important;
}

.bootstrap-input .form-label {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: .85rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  position: relative;
}


.form-control:focus, .form-select:focus {
  border-color: #1976d2 !important;
  box-shadow: none !important;
}
.text-green { color: #0ff00d; }
.text-purple {color: #673ab7}
/* .text- { color: #0ff00d; } */


/** Customize Swal **/
.swal2-styled.swal2-cancel {
  /* FONT-VARIANT: JIS04; */
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #f5f5f5 !important;
  color: #000000de !important;
  padding: 6px 16px;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
}

.swal2-styled.swal2-confirm {
  border: 0; 
  color: #fff;
  font-size: 1em;
  border: 0;
  border-radius: 0.25em;
  background: initial;
  padding: 6px 16px;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none;
}

.swal2-popup {
  width: 26em;
}

.swal2-title {
  font-size: 1.25em;
}

.swal2-html-container {
  font-size: .925em;
}

.swal2-icon {
  width: 4em;
  height: 4em;
}

.file-chip-primary {
  background-color: #1976d212 !important;
  border: none !important;
  color: #1976d2d4 !important;
}

.file-chip-danger {
  background-color: #d32f2f12 !important;
  border: none !important;
  color: #d32f2fd9 !important;
}

.file-chip-primary svg {
  transform: rotate(46deg) !important;
}
.doc:hover{
  color:#1976D2 !important;
}

 