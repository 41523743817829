.prequalification {
    cursor: pointer;
    padding: .75rem;
    margin-bottom: .75rem;

}

.prequalification:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.preq-icon-wrapper {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 0.5rem;
    border: 1px solid #dee2e6;
    background-color: #fff;
}

.preq-icon-wrapper .preq-icon {
    font-size: 2rem;
    color: #1976d2;
}

.preq-text-wrapper * {
    margin-bottom: .25rem;
}

.preq-text-wrapper {
    margin-top: .4rem;
    margin-left: .5rem;
}

.status {
    margin-top: .85rem;
}

.status-icon {
    font-size: 1.05rem !important;
}


.dates {
    margin-top: .85rem;
}

.btn-view {
    border-color: #e7e7e7;
}