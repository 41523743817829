.view-preq {
    padding: .75rem;
    margin-bottom: .75rem;
}

.view-preq-text-wrapper {
    margin-top: 0.4rem;
    margin-left: 1rem;
}

.view-preq-dates-wrapper {
    margin-left: 5rem;
}

.btn-w { width: 50%; }

@media only screen and (max-width: 767px) {
    .btn-w { width: 100%; }
}
